import React, { useState } from "react";
import logo from './logo.svg';
import './App.css';
import Webcam from "react-webcam";

const App = () => {
  const [imgSrc, setImgSrc] = useState();
  const [cam, setCam] = useState('user')

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: cam
  };

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log(imageSrc)
      setImgSrc(imageSrc);
    },
    [webcamRef]
  );

  const switchCamera = () => {
    if (cam == 'user') {
      console.log('env');
      setCam("environment")
    } else {
      console.log('user');
      setCam("user")
    }
  }

  return (
    <div className="App">
      {imgSrc ?
      <>
      <img src={`${imgSrc}`} />
      <button onClick={() => setImgSrc('')}></button>
      </>
      : <>

      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        screenshotQuality="1"
        videoConstraints={videoConstraints}
      />
      <button className="switchCam" onClick={() => switchCamera()}></button>
      <button onClick={capture}></button>
      </>
}
    </div>
  );
}

export default App;
